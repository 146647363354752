$main-color: #ba2526;//#ba2526 0073B5
ion-progress-bar{
  --progress-background: $main-color;
}
ion-segment-button {
  --color-checked: $main-color ;
}
.checking-data{
  color: $main-color !important;
}

#subpage-detail{
  h2{
    color: $main-color
  }
}
#type-detail-page{
  .tag-title{
    color: $main-color
  }
}

.topmenu-link{
  color : #fff !important;
}
.dashboard-item-text{
  color : $main-color !important;
}

.mail-button{
  background : $main-color !important;
  border: 1px solid $main-color;
}

.location-text{
  color : $main-color !important;
}

.day-selector , .day-selector-item{
  background : $main-color !important;
}
.day-number-selected{
  color : $main-color !important;
}

.edit-profile-button{
  color : $main-color !important;
}
.edit-profile-button:hover{
  background-color: $main-color;
}

.send-button-text{
  color : $main-color !important;
}
.tile-item {
  background-color: #fff;
  color : $main-color !important;
}
.header-text{
  background-color: $main-color;
}
.profile-button-mobile, .logout-button-mobile{
  color: #fff;
  background-color : $main-color !important;
}

.desktop-user-button{
  color : $main-color !important;
}

.scanlist-banner{
  background: $main-color;
}
.dashboard-sub-background{
  background: $main-color;
}
