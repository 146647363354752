$brand-color: #ba2526;
$brand-font: #fff;
$tile-color: white;

$section-header-color: #fff;


ion-header ion-toolbar {
  --background: rgb(255, 255, 255);
  --color: $brand-color;
}


body,
.ionic-body,
input,
button,
select,
textarea {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif !important;


}

ion-menu-toggle {
  a {
    text-decoration: none;
  }
}

.person-expand-icon {
  position: absolute;
  top: 10px;
  right: 20px;
}

.available-dot {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  right: 7px;
  top: 7px;
  display: inline-block;
  background: green;
}

.wcpt-session-divider-time {
  font-size: 15px !important;
}

#presentation-list {
  .speaker-line {
    margin-left: 10px;
    font-size: 0.8rem;
    color: grey !important;

    small:not(:last-child)::after {
      content: ", ";
      display: inline-block
    }

    span {
      font-size: 0.8rem;
      color: grey;
    }
  }

  .list-outline {
    ul {
      list-style: square;
      margin-bottom: 20px;
    }

    ul li {
      color: #293039;
      font-size: 15px;
      margin-left: 20px;
      white-space: normal;
    }

    ol {
      list-style: decimal;
      margin-bottom: 20px;
    }

    ol li {
      color: #293039;
      font-size: 15px;
      margin-left: 20px;
      white-space: normal;
    }

    p {
      margin-top: 10px;
    }
  }

}

.delete-indicator {
  position: absolute;
  top: 5px;
  right: 5px;
  color: red;
  font-size: 1.4rem;
  z-index: 4;
}

.abs-text {
  h2 {
    display: block;
    font-size: 1.5em;
    margin-top: 0.83em;
    margin-bottom: 0.83em;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
  }
}

#abstract-detail {
  .action-buttons {
    display: flex;

    .action-btn {
      font-size: 1rem;
      background: $brand-color;
      color: $brand-font;
      border-radius: 50px;
      height: 50px;
      width: 200px;
      text-align: center;
      padding-top: 12px;
      margin: 20px 0px 20px 20px;
    }
  }
}

@media (min-width: 768px) {
  ion-menu {
    width: 300px;
  }
  .wcpt-header {
    position: relative;
    height: 180px;
    background-color: #41b6e6;
    width: 100%;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;

    .nav-area {
      height: 40px;
      background: #444; //#004cff;
      .nav-icon {
        position: absolute;
        top: 0px;
        left: 17px;
        width: 40px;
        height: 40px;
        font-size: 2.6rem;
        color: white;
      }

      .back-icon {
        position: absolute;
        top: 5px;
        left: 17px;
        width: 40px;
        height: 40px;
        font-size: 2rem;
        color: white;
      }

      .live-clock {
        position: absolute;
        top: 10px;
        right: 10px;
        color: white;
        font-size: 1.2rem;
      }
    }

    .header-content {
      height: 140px;

      img {
        position: absolute;
        top: 45px;
        left: 0px;
        height: 130px;
      }

      .nav-icon {
        position: absolute;
        top: 90px;
        left: 20px;
        width: 40px;
        height: 40px;
        font-size: 3rem;
        color: white;
      }

      .tag {
        position: absolute;
        top: 70px;
        right: 10px;
        color: #fff;
        font-size: 1.8rem;
        width: 250px;
      }

      .right-buttons {
        position: absolute;
        top: 50px;
        right: 20px;
        width: 200px;
        display: block;
        font-weight: bold;

        .btn-help {
          position: absolute;
          display: block;
          width: 200px;
          height: 50px;
          border-radius: 50px;
          font-size: 1.2rem;
          padding-top: 12px;
          text-align: center;
          background: $brand-color;
          color: $brand-font;
        }

        .btn-register {
          position: absolute;
          top: 60px;
          font-size: 1.2rem;
          background: $brand-color;
          color: $brand-font;
          border-radius: 50px;
          height: 50px;
          width: 200px;
          text-align: center;
          padding-top: 12px;
        }
      }
    }
  }

  .section-header {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    //height: 60px;
    width: 100%;
    background: $brand-color;
    //z-index: 1000;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);

    h1 {
      color: $section-header-color;
      margin: 0 10px 0 20px;

      padding-top: 5px;
      display: inline-block;
    }

    h2 {
      color: $section-header-color;
      margin: 0 10px 0 20px;
      padding-top: 5px;
      display: inline-block;
    }

    .person-head {
      color: $section-header-color;
      margin: 15px 10px 10px 20px;
      font-size: 1.8rem;
      padding-top: 5px;
      display: inline-block;
    }

    .person-head-right {
      position: absolute;
      right: 10px;
      color: $section-header-color;
      margin: 10px 10px 0 20px;
      font-size: 1.8rem;
      padding-top: 5px;
      display: inline-block;
    }

    .session-head {
      color: $section-header-color;
      padding: 20px;
      padding-top: 10px;
      padding-bottom: 0px;
      display: inline-block;
      margin-bottom: 10px;
      font-size: 20px;

      .title {
        font-size: 18px;
      }

      .type {
        margin-top: -5px;
        font-size: 0.9rem;
        display: inline-block;
        line-height: 0.8rem;

        strong {
          display: inline-block;
          font-size: 0.7rem;
        }
      }
    }

    .item-header-gradient {
      position: absolute;
      //z-index: -1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: url('assets/shadow.png') no-repeat bottom center;
      background-size: cover;
    }

    .session-head-right {

      color: white;
      padding-left: 20px;
      padding-bottom: 15px;
      padding-top: 0px;
      font-size: 14px;
    }


    .search-input {
      display: inline-block;
      width: calc(90% - 200px);
      height: 40px;
      border: none;
      border-radius: 50px;
      margin-left: auto;
      margin-right: 10px;
      padding-left: 10px;
      margin-top: -10px;
    }
  }

  #wcpt-sessions,
  #wcpt-grid {
    .control {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      margin-top: 10px;
      padding-right: 20px;
    }

    .switch-btn {
      text-decoration: none;
      font-size: 1.2rem;
      background: $brand-color;
      color: $brand-font;
      border-radius: 50px;
      height: 50px;
      width: 200px;
      text-align: center;
      padding-top: 8px;
      margin: 20px 0px 20px 20px;
      vertical-align: top;
      flex: 0 0 200px;

      i {
        font-size: 2rem;
      }

      span {
        vertical-align: top;
        display: inline-block;
        margin-top: 6px;
      }
    }
  }


  .wcpt-session-list {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    justify-content: flex-start;


  }
  #wcpt-person-detail {


    .person-sessions-header {
      text-align: left;
      background: $brand-color;
      color: white;
      height: 40px;

      padding-left: 10px;
    }

    .person-sessions-text {
      margin: 5px;
      margin-top: 15px !important;

      padding-top: 7px;
    }


    .person-detail-header {
      width: 100%;
      min-height: 170px;
      height: auto;
      display: block;
      background: white;

      color: #24189b;


      img {
        top: 0px;
        position: absolute;
        width: 170px;
        height: 170px;
        padding: 20px;
        object-fit: cover;
      }

      .vitae {
        color: #3d5a59;
        margin: 25px 0 0 180px;
        display: inline-block;
        width: calc(90% - 100px);
        font-size: 0.9rem;
      }
    }

    .person-buttons {
      display: flex;
      width: 100%;
      text-align: center;

      justify-content: center;

      .person-btn {
        font-size: 1.2rem;
        background: $brand-color;
        color: $brand-font;
        border-radius: 50px;
        height: 50px;
        width: 200px;
        text-align: center;
        padding-top: 12px;
        margin: 20px 0px 20px 20px;
      }
    }

  }


  .actn-btn {
    color: rgb(80, 78, 78);
    width: 60px;
    padding: 5px;
    text-align: center;
    line-height: 1.0;
    margin-left:50px;
    margin-right: 50px;
  }
  .actn-icon {

    display: inline-block;
    font-size: 30px;
  }
  .actn-text {
    text-align: center;
    display: inline-block;
    font-size: 11px;
  }
  .action-buttons {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    height: 80px;
    display: flex;
    max-width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    border-bottom: 1px solid rgb(231, 229, 229);


  
  }

  #wcpt-session-detail {


    .session-content {
      width: 100%;
      display: flex;

      .left-side {
        width: 70%;
        height: 100%;
      }

      .stream-container {
        width: 100%;
        max-width: 1100px;

        .stream {
          position: relative;
          width: 100%;
          padding-top: 56.25%;

          .info-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 3px dashed grey;

            .info {
              flex: 0 0 400px;
              font-size: 2rem;
              line-height: 2.2rem;
              color: grey;
              font-weight: bold;
            }
          }
        }
      }

      .right-side {
        margin-left: 10px;
        padding: 0 10px;
        border-left: 1px solid lightgrey;
        width: 30%;
      }
    }


    .session-info {
      display: block;
      margin-left: 25px;
      font-size: 1.1rem;
      font-weight: bold;
      color: #b14545;
    }

    .outline {
      margin-left: 25px;

      ul {
        list-style: square;
        margin-bottom: 20px;
      }

      ul li {
        color: #293039;
        font-size: 15px;
        margin-left: 20px;
        white-space: normal;
      }

      ol {
        list-style: decimal;
        margin-bottom: 20px;
      }

      ol li {
        color: #293039;
        font-size: 15px;
        margin-left: 20px;
        white-space: normal;
      }
    }
  }

  .dashboard-sub-background {
    overflow: auto;
    height: 100%;
    background:$brand-color
  }


  #wcpt-exhibitor-detail {
    color: #3d5a59;

    h1 {
      margin: 30px 0 0px 30px;
      color: #3d5a59;
    }

    a {
      text-decoration: none;
      color: $brand-color;
    }

    .exhibitor-description {
      width: 58%;
      margin-left: 20px;
      display: inline-block;

      .about {
        padding: 10px;
        background: #fff;
      }

      .contact-area {
        font-size: 1.3rem;
        line-height: 1.42rem;

        .contact-item {
          margin-top: 10px;
          display: block;

          i {
            font-size: 2.5rem;
            margin-right: 5px;
          }

          span {
            vertical-align: text-top;
          }
        }

        .contact {
          width: 48%;
          display: flex;
          flex-flow: column;
        }

        .address {
          margin-top: 10px;
          width: 48%;
          display: inline-block;
          vertical-align: top;
        }
      }
    }

    .exhibitor-profile {
      display: inline-block;
      width: 38%;
      margin-left: 20px;
      vertical-align: top;

      .logo {
        img {
          max-width: 98%;
        }
      }
    }

    .exhibitor-resources {
      width: 100%;
      display: flex;
      flex-flow: row wrap;

      .video-container {
        margin-left: 20px;
        width: 58%;
        display: flex;
        flex-flow: row wrap;

        .video-slot {
          width: 48%;
          margin: 5px;

          .stream {
            position: relative;
            width: 100%;
          }
        }
      }

      .file-container {
        width: 38%;
        margin-left: 20px;
        vertical-align: top;
        display: inline-block;

        .files {
          display: flex;
          height: 100%;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: flex-start;

          .file {
            display: block;
            width: 30%;
            flex: 0 0 30%;

            margin: 0 25px 20px 0;

            .thumbnail {
              position: relative;
              padding-top: 130%;
              border: 1px solid #3d5a59;
              margin-bottom: 10px;

              .thumb-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .text {
                  position: absolute;
                  display: block;
                  height: 40px;
                  width: 100%;
                  background: rgba(211, 211, 211, 0.7);
                  left: 0;
                  bottom: 0;
                  font-size: 1rem;
                  text-align: center;
                  padding-top: 10px;
                  line-height: 1rem;
                  color: grey;
                  font-weight: bold
                }

                img {
                  flex: 0 0 100%;
                  max-width: 100%;
                }
              }
            }

            .thumb-action {
              display: flex;

            }

            .dl-btn {
              text-align: center;
              font-size: 1.8rem;
              width: 80%;
              margin: auto;
              height: 50px;
              margin-bottom: 20px;
            }
          }
        }

      }

      .link-container {
        display: flex;
        //height: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;

        vertical-align: top;

        .link-btn {
          width: 95%;
        }
      }
    }


    .web-exhibitor-divider {
      flex: 0 0 100vw;
      width: 100vw;
      padding-left: 20px;
      margin-bottom: 10px;
      font-size: 1.2rem;
    }

    .exhibitor-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;

      .exhibitor-btn {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 1rem;
        background: $brand-color;
        color: $brand-font;
        border-radius: 50px;
        height: 50px;
        width: 200px;
        text-align: center;
        margin: 20px 0px 0px 20px;


      }

      .request-btn {
        margin-top: 0px !important;
        width: 420px;
        display: inline-block;
      }
    }
  }
  .web-person-divider {
    font-size: 20px;
    margin: 10px;

    margin-top: 20px;
  }
  #wcpt-sessiontypes {
    .content {
      overflow: auto;
      margin-top: 25px;
      margin-right: 10px;
      margin-left: 10px;

      &::-webkit-scrollbar {
        display: none;
      }

      .sessiontype-wrapper {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        padding-top: 15px;
        justify-content: flex-start;

        .sessiontype-item {
          flex: 0 1 30%;
          display: flex;
          flex-direction: row;
          flex-flow: row wrap;
          min-width: 140px;
          min-height: 45px;
          //width: calc(33.333% - 20px);
          max-height: 70px;
          width: 70px;
          max-width: 140px;
          border-radius: 10px;
          margin-left: 10px;
          margin-right: 10px;
          margin-bottom: 35px;
          text-align: center;
          line-height: 0.9rem;
          color: #fff;
          text-decoration: none;

          .count {
            flex: 0 1 100%;
            font-size: 2.6em;
            width: 60px;
            padding-top: 15px;
          }

          .text {
            flex: 0 1 100%;
            font-size: 0.75rem;
            line-height: 0.75rem;
            overflow-y: visible;
            width: 75px;
            margin-top: 20px;
            color: #000;
            font-weight: bold;
            margin-bottom: 5px;
          }
        }
      }
    }

    .content {
      .sessiontype-wrapper {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        padding-top: 15px;
        max-width: 1500px;
        margin: 0 auto;
        justify-content: center;

        .sessiontype-item {
          font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
          position: relative;
          flex: 0 1 220px;
          width: 220px;
          height: 160px;
          max-width: 220px;
          max-height: 160px;
          border: 1px solid lightgrey;

          .text {
            position: absolute;
            top: 5px;
            left: 10px;
            width: 180px;
            text-align: left;
            font-size: 1.3rem;
            margin-top: 5px;
            line-height: 1.4rem;
          }

          .count {
            display: block;
            position: absolute;
            bottom: 10px;
            right: 0px;
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  #wcpt-presentation-detail {


    .presentation-content {
      width: 100%;
      display: flex;
      padding-left: 2px;

      .left-side {
        width: 70%;
        height: 100%;
      }

      .stream-container {
        margin: 0 0px;
        width: calc(100% - 40px);

        .stream {
          position: relative;
          width: 100%;
          padding-top: 56.25%;

          .info-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 3px dashed grey;

            .info {
              flex: 0 0 400px;
              font-size: 2rem;
              line-height: 2.2rem;
              color: grey;
              font-weight: bold;
            }
          }
        }
      }

      .right-side {
        margin-left: 10px;
        padding: 0 10px;
        border-left: 1px solid lightgrey;
        width: 30%;
      }

      .presentation-info {
        display: block;
        height: 60px;
        margin-left: 25px;
        font-size: 1.1rem;
        font-weight: bold;
        color: #b14545;
      }

      .persons {
        margin-left: 20px;
        width: 38%;
      }
    }

    .action-buttons {
      display: flex;

      .action-btn {
        font-size: 1.2rem;
        background: $brand-color;
        color: $brand-font;
        border-radius: 50px;
        height: 50px;
        width: 200px;
        text-align: center;
        padding-top: 12px;
        margin: 20px 0px 20px 20px;
      }
    }

    .outline {
      margin-left: 25px;
    }
  }

  .presentation-list-item-modern {
    margin-left: -20px;

    .timeslot {
      display: flex;
      align-items: center;

      .time-icon {
        font-size: 1.2rem;
        color: gray;
        margin-right: 2px
      }

      .time {
        padding-top: 1px;
        color: gray;
        font-size: 1.1rem;
        max-width: 80px;
        display: block;
        font-weight: bold;
      }
    }

    .content {
      font-size: 1rem;
      line-height: 1.1rem;

      .title {
        display: block;
        font-size: 1.0rem;
        padding-top: 6px
      }

      .speaker-line {
        display: block;
      }
    }
  }
}

#presentation-view {
  .section-header {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    height: 60px;
    width: 100%;
    background: $brand-color;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);

    h1 {
      color: $brand-color;
      margin: 10px 0px 0 20px;
      font-size: 1.2rem;
      padding-top: 5px;
      white-space: normal;
      width: 260px;
      max-width: 260px;
      display: inline-block;
    }

    h2 {
      color: $brand-color;
      margin: 0 10px 0 20px;
      padding-top: 15px;
      font-size: 1.2rem;
      display: inline-block;
    }

    .person-head {
      color: $section-header-color;
      margin: 10px 10px 0 20px;
      font-size: 1rem;
      padding-top: 5px;
      display: inline-block;
    }

    .person-head-right {
      position: absolute;
      right: 10px;
      color: $section-header-color;
      margin: 10px 10px 0 20px;
      font-size: 1.8rem;
      padding-top: 5px;
      display: inline-block;
    }

    .session-head {
      color: $section-header-color;
      margin: 10px 10px 0 10px;
      display: inline-block;

      .title {
        font-size: 1.1rem;
      }

      .type {
        margin-top: -5px;
        font-size: 0.7rem;
        display: inline-block;
        line-height: 0.8rem;

        strong {
          display: inline-block;
          font-size: 0.7rem;
        }
      }
    }

    .item-header-gradient {
      position: absolute;
      //z-index: -1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: url('assets/shadow.png') no-repeat bottom center;
      background-size: cover;
    }

    .session-head-right {
      position: absolute;
      right: -20px;
      color: $section-header-color;
      margin: 0px 0px 0 20px;
      font-size: 0.9rem;
      line-height: 1rem;
      display: inline-block;
      width: 120px;
    }

    .search-input {
      display: inline-block;
      width: calc(100% - 260px);
      height: 40px;
      border: none;
      border-radius: 50px;
      margin-left: auto;
      margin-right: 10px;
      padding-left: 10px;
      margin-top: 0px;
    }
  }
}

#presentation-list {
  .icons {
    position: absolute;
    bottom: 5px;
    right: 10px;
    width: 40px;
    display: flex;
    justify-content: flex-end;

    div {
      font-size: 2rem;
      margin: 0 2px;
      color: #e2b007;
    }
  }
}

.search-reset-btn {
  display: inline-block;
  font-size: 2rem;
  color: #fff;
}

#navigation .item {
  color: #004cff;

  span {
    color: #004cff;
    font-weight: bold;
  }
}

.wcpt-list {
  font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0;

  .wcpt-list-item {
    text-align: center;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    flex: 0 0 calc(33% - 20px);
    width: auto;
    min-width: 400px;
    max-width: 33%;
    height: auto;
    display: block;
    background: #ffffff;
    border-radius: 20px;
    font-size: 1rem;
    margin: 40px 20px;
    color: #333333;
    -moz-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
    border: none;
  }
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  font-size: 2rem;
  align-items: center;
  margin: 0 auto;
  color: #fff;
  margin-top: 20px !important;

  .spinner {
    width: 50px;
    height: 50px;

    svg {
      width: 40px;
      height: 40px;
      stroke: #fff;
    }
  }
}

.loading-indicator-dark {
  display: flex;
  flex-direction: column;
  max-width: 280px;
  font-size: 2rem;
  align-items: center;
  margin: 0 auto;
  color: #000;

  .spinner {
    width: 50px;
    height: 50px;

    svg {
      width: 40px;
      height: 40px;
      stroke: #000;
    }
  }
}

.scroll-content {
  margin-top: 152px;

  overflow: inherit;

  overflow-y: scroll;
}

@media (max-width: 768px) {
  .wcpt-header {
    position: relative;
    height: 120px;
    background-color: #41b6e6;
    width: 100%;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;

    .nav-area {
      height: 40px;
      background: #444; //#004cff;
      .nav-icon {
        position: absolute;
        top: 0px;
        left: 17px;
        width: 40px;
        height: 40px;
        font-size: 2.6rem;
        color: white;
      }

      .back-icon {
        position: absolute;
        top: 5px;
        left: 17px;
        width: 40px;
        height: 40px;
        font-size: 2rem;
        color: white;
      }

      .live-clock {
        position: absolute;
        top: 10px;
        right: 10px;
        color: white;
        font-size: 1.2rem;
      }
    }

    .header-content {
      height: 120px;

      img {
        position: absolute;
        top: 45px;
        left: 0px;
        height: 60px;
      }

      .nav-icon {
        position: absolute;
        top: 90px;
        left: 20px;
        width: 40px;
        height: 40px;
        font-size: 3rem;
        color: white;
      }

      .tag {
        position: absolute;
        top: 45px;
        right: 0px;
        color: #fff;
        font-size: 1.1rem;
        width: 160px;
      }

      .right-buttons {
        position: absolute;
        top: 10px;
        right: 0px;
        width: 160px;
        display: block;
        font-weight: bold;

        .btn-help {
          position: absolute;
          display: block;
          width: 200px;
          height: 50px;
          border-radius: 50px;
          font-size: 1.2rem;
          padding-top: 12px;
          text-align: center;
          background: $brand-color;
          color: $brand-font;
        }

        .btn-register {
          position: absolute;
          top: 60px;
          font-size: 0.9rem;
          background: $brand-color;
          color: $brand-font;
          border-radius: 50px;
          height: 40px;
          width: 150px;
          text-align: center;
          padding-top: 8px;
        }
      }
    }
  }

  #presentation-view {
    .section-header {
      font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
      position: relative;
      height: 60px;
      width: 100%;
      background: $brand-color;
      box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);

      h1 {
        color: $section-header-color;
        margin: 0px 0px 0 20px;
        font-size: 1.2rem;
        padding-top: 5px;
        white-space: normal;
        width: 160px;
        max-width: 160px;
        display: inline-block;
      }

      h2 {
        color: $section-header-color;
        margin: 0 10px 0 20px;
        padding-top: 15px;
        font-size: 1.2rem;
        display: inline-block;
      }

      .person-head {
        color: $section-header-color;
        margin: 10px 10px 0 20px;
        font-size: 1rem;
        padding-top: 5px;
        display: inline-block;
      }

      .person-head-right {
        position: absolute;
        right: 10px;
        color: $section-header-color;
        margin: 10px 10px 0 20px;
        font-size: 1.8rem;
        padding-top: 5px;
        display: inline-block;
      }

      .session-head {
        color: $section-header-color;
        margin: 10px 10px 0 10px;
        display: inline-block;

        .title {
          font-size: 1.1rem;
        }

        .type {
          margin-top: -5px;
          font-size: 0.7rem;
          display: inline-block;
          line-height: 0.8rem;

          strong {
            display: inline-block;
            font-size: 0.7rem;
          }
        }
      }

      .item-header-gradient {
        position: absolute;
        //z-index: -1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: url('assets/shadow.png') no-repeat bottom center;
        background-size: cover;
      }

      .session-head-right {
        position: absolute;
        right: -20px;
        color: $section-header-color;
        margin: 0px 0px 0 20px;
        font-size: 0.9rem;
        line-height: 1rem;
        display: inline-block;
        width: 120px;
      }

      .search-input {
        display: inline-block;
        width: calc(100% - 260px);
        height: 40px;
        border: none;
        border-radius: 50px;
        margin-left: auto;
        margin-right: 10px;
        padding-left: 10px;
        margin-top: -20px;
      }
    }
  }

  #persons-list,
  #search-page {
    .section-header {
      h1 {
        max-width: 70px;
      }

      .search-input {
        margin-top: 10px;
        width: calc(100% - 145px);
      }

      .search-reset-btn {
        position: absolute;
        top: 12px;
        right: 10px;
        //padding-top:10px;
      }
    }
  }
  .session-corner-annotation {
    background-image: linear-gradient(to right, rgb(197, 50, 50), rgb(179, 68, 68));
    position: absolute;
    top: -15px;
    right: 0px;
    width: 150px;
    height: 90px;
    -webkit-transform: rotate(45deg);
    //box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5)
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  }

  .annotation-text {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
    color: white;
  }

  #sponsor-list {
    .section-header {
      h1 {
        max-width: 70px;
      }

      .search-input {
        margin-top: -10px;
        width: calc(100% - 145px);
      }

      .search-reset-btn {
        position: absolute;
        top: 12px;
        right: 10px;
        //padding-top:10px;
      }
    }
  }

  .section-header {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    position: relative;
    //height: 60px;
    width: 100%;
    background: $brand-color;
    //z-index: 1000;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1);

    h1 {
      color: $section-header-color;
      margin: 0 0px 0 20px;
      font-size: 1rem;
      padding-top: 5px;
      white-space: normal;
      width: 160px;
      max-width: 160px;
      display: inline-block;
      margin-bottom: 10px;
    }

    h2 {
      color: $section-header-color;
      margin: 0 10px 0 20px;
      padding-top: 15px;
      font-size: 1.2rem;
      display: inline-block;
    }

    .person-head {
      color: $section-header-color;
      margin: 10px 10px 0 20px;
      font-size: 1rem;
      padding: 5px;
      padding-left: 0px;
      display: inline-block;
    }

    .person-head-right {
      position: absolute;
      right: 10px;
      color: $section-header-color;
      margin: 10px 10px 0 20px;
      font-size: 1.8rem;
      padding-top: 5px;
      display: inline-block;
    }

    .session-head {
      color: $section-header-color;
      padding: 20px;
      padding-top: 10px;
      padding-bottom: 0px;
      display: inline-block;
      margin-bottom: 10px;
      font-size: 14px;

      .title {
        font-size: 14px;
      }

      .type {
        margin-top: -5px;
        font-size: 0.8rem;
        display: inline-block;
        line-height: 0.8rem;

        strong {
          display: inline-block;
          font-size: 0.7rem;
        }
      }
    }

    .item-header-gradient {
      position: absolute;
      //z-index: -1;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: url('assets/shadow.png') no-repeat bottom center;
      background-size: cover;
    }

    .session-head-right {

      color: white;
      padding-left: 20px;
      padding-bottom: 15px;
      padding-top: 0px;
      font-size: 12px;
    }


    .search-input {
      display: inline-block;
      width: calc(90% - 160px);
      height: 40px;
      border: none;
      border-radius: 50px;
      margin-left: auto;
      margin-right: 10px;
      padding-left: 10px;
      margin-top: -10px;
    }
  }

  #sessionGridComponent {
    .corner-item {
      top: 95px !important;
    }
  }

  #wcpt-sessions,
  #wcpt-grid {
    .control {
      display: flex;
      //display:none;
      width: 100%;
      justify-content: flex-end;
      padding-right: 10px;
    }

    .switch-btn {
      text-decoration: none;
      font-size: 0.8rem;
      background: $brand-color;
      color: $brand-font;
      border-radius: 50px;
      height: 35px;
      width: 200px;
      text-align: center;
      margin: 15px 5px 10px 10px;
      //margin: 20px 0px 20px 20px;
      vertical-align: top;
      flex: 0 0 150px;

      i {
        margin-top: 5px;
        display: inline-block;
        font-size: 1.2rem;
      }

      span {
        vertical-align: top;
        display: inline-block;
        margin-top: 6px;
      }
    }
  }
  .wcpt-session-divider {
    background-color: $brand-color !important;
    color: white !important;
    padding: 8px;
  }


  .wcpt-session-list {
    padding-top: 0px !important;
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: block;
    flex-flow: row wrap;
    width: 100vw;
    justify-content: flex-start;

  }


  #wcpt-person-detail {


    .person-sessions-header {
      text-align: left;
      background: $brand-color;
      color: white;
      //height: 40px;
      line-height: 2;
      padding-left: 10px;
    }

    .person-sessions-text {
      margin: 5px;
      margin-top: 15px !important;
      padding-top: 7px;
    }


    .person-detail-header {
      width: 100%;
      min-height: 170px;
      display: block;
      background: white;
      color: #3d5a59;

      img {
        position: absolute;
        top: 30px;
        width: 140px;
        height: 140px;
        padding: 10px;
        object-fit: cover;
      }

      .vitae {
        margin: 25px 40px 0px 160px;
        display: inline-block;
        width: calc(100% - 200px);
        padding-right: 10px;
        font-size: 0.9rem;
        overflow: hidden;
      }
    }

    .person-buttons {
      display: flex;

      width: 100%;
      text-align: center;

      justify-content: center;

      .person-btn {
        font-size: 1.2rem;
        background: $brand-color;
        color: $brand-font;
        border-radius: 50px;
        height: 50px;
        width: 200px;
        text-align: center;
        padding-top: 12px;
        margin: 20px 0px 20px 20px;
      }
    }
  }
  .actn-btn {
    color: rgb(80, 78, 78);
    margin-left: 10px;

    margin-right: 10px;
    width: 65px;
    padding: 5px;
    text-align: center;
    line-height: 1.0;
  }
  .actn-icon {

    display: inline-block;
    font-size: 30px;
  }
  .actn-text {
    width: 100%;
    text-align: center;
    display: inline-block;
    font-size: 11px;
  }

  .action-buttons {
    padding: 10px;
    //margin-left: 5px;
    //margin-right: 5px;
    height: 80px;
    display: flex;
    max-width: 100%;
    flex-flow: row wrap;
    justify-content: center;
    //border-bottom: 1px solid rgb(231, 229, 229);
    text-align: center;


    .action-btn {
      font-size: 0.9rem;
      line-height: 1rem;
      background: $brand-color;
      color: $brand-font;
      border-radius: 50px;
      height: 40px;
      width: 140px;
      text-align: center;
      padding-top: 12px;
      margin: 5px;
    }
  }

  #wcpt-session-detail {
    .session-content {
      width: 100%;
      display: flex;
      flex-flow: row wrap;

      .left-side {
        width: 100%;
        height: 100%;
      }

      .stream-container {
        width: 100%;
        max-width: 1100px;

        .stream {
          position: relative;
          width: 100%;
          padding-top: 56.25%;

          .info-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 3px dashed grey;

            .info {
              flex: 0 0 400px;
              font-size: 2rem;
              line-height: 2.2rem;
              color: grey;
              font-weight: bold;
            }
          }
        }
      }

      .right-side {
        margin-left: 10px;
        padding: 0 10px;
        border-left: 1px solid lightgrey;
        width: 100%;
      }
    }

    .actn-btn {
      color: rgb(80, 78, 78);
      width: 20%;
      padding: 5px;
      text-align: center;
      line-height: 1.0;
    }

    .actn-icon {

      display: inline-block;
      font-size: 30px;
    }

    .actn-text {
      text-align: center;
      display: inline-block;
      font-size: 11px;
    }

    .action-buttons {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
      height: 80px;
      display: flex;
      max-width: 100%;
      flex-flow: row wrap;
      justify-content: center;
      border-bottom: 1px solid rgb(231, 229, 229);


      .action-btn {
        font-size: 0.9rem;
        line-height: 1rem;
        background: $brand-color;
        color: $brand-font;
        border-radius: 50px;
        height: 40px;
        width: 140px;
        text-align: center;
        padding-top: 12px;
        margin: 5px;
      }
    }

    .session-info {
      display: block;
      margin-left: 25px;
      font-size: 1.1rem;
      font-weight: bold;
      color: #b14545;
    }

    .outline {
      margin-left: 15px;

      ul {
        list-style: square;
        margin-bottom: 20px;
      }

      ul li {
        color: #293039;
        font-size: 15px;
        margin-left: 20px;
        white-space: normal;
      }

      ol {
        list-style: decimal;
        margin-bottom: 20px;
      }

      ol li {
        color: #293039;
        font-size: 15px;
        margin-left: 20px;
        white-space: normal;
      }
    }
  }

  #wcpt-exhibitor-detail {
    color: #24189b;

    h1 {
      margin-left: 20px;
      color: #24189b;
      font-size: 1.2rem;
    }

    a {
      text-decoration: none;
      color: #3d5a59;
    }

    .exhibitor-description {
      width: 90%;
      margin-left: 20px;
      display: inline-block;

      .about {
        padding: 10px;
        background: #fff;
      }

      .contact-area {
        font-size: 0.9rem;
        line-height: 1rem;

        .contact-item {
          margin-top: 10px;
          display: block;

          i {
            font-size: 1.5rem;
            margin-right: 5px;
          }

          span {
            vertical-align: text-top;
          }
        }

        .contact {
          width: 49%;
          display: inline-block;
        }

        .address {
          margin-top: 10px;
          width: 49%;
          display: inline-block;
          vertical-align: top;
        }
      }
    }

    .exhibitor-profile {
      display: inline-block;
      width: 100%;
      margin: 20px 0 0 0;

      vertical-align: top;

      .logo {
        img {
          max-width: 98%;
        }
      }
    }

    .exhibitor-resources {
      width: 100%;
      display: flex;
      flex-flow: row wrap;

      .video-container {
        margin-left: 20px;
        width: 90%;

        .stream {
          position: relative;
          width: 100%;
          padding-top: 56.25%;
        }
      }

      .file-container {
        width: 100%;
        margin: 20px 0 0 20px;
        vertical-align: top;
        display: inline-block;

        .files {
          display: flex;
          height: 100%;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: flex-start;

          .file {
            display: block;
            width: 28%;
            flex: 0 0 45%;

            margin: 0 15px 20px 0;

            .thumbnail {
              position: relative;
              padding-top: 130%;
              border: 1px solid #004cff;
              margin-bottom: 10px;

              .thumb-content {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .text {
                  position: absolute;
                  display: block;
                  height: 40px;
                  width: 100%;
                  background: rgba(211, 211, 211, 0.7);
                  left: 0;
                  bottom: 0;
                  font-size: 0.9rem;
                  text-align: center;
                  padding-top: 10px;
                  line-height: 1rem;
                  color: grey;
                  font-weight: bold;
                }

                img {
                  flex: 0 0 100%;
                  max-width: 100%;
                }
              }
            }

            .thumb-action {
              display: flex;

            }

            .dl-btn {
              text-align: center;
              font-size: 1.8rem;
              width: 80%;
              margin: auto;
              height: 50px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .web-exhibitor-divider {
      flex: 0 0 100vw;
      width: 100vw;
      padding-left: 20px;
      margin-bottom: 10px;
      font-size: 1.2rem;
    }

    .exhibitor-buttons {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;

      margin-right: 10px !important;


      .exhibitor-btn {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        justify-content: center;
        font-size: 0.9rem;
        line-height: 1rem;
        background: $brand-color;
        color: $brand-font;
        border-radius: 50px;
        height: 50px;
        width: 150px;
        text-align: center;
        margin: 20px 0px 0px 20px;

      }

      .request-btn {

      }
    }
  }

  .web-person-divider {
    padding: 5px;
    background: #F8F8F8;
    border-bottom: 0.1px solid #CBCBCB
  }

  .section-divider {
    padding: 5px;
    background: #F8F8F8;
    border-bottom: 0.1px solid #CBCBCB
  }

  .section-title {
    margin-left: 25px;
    font-weight: 500;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  #wcpt-sessiontypes {
    .content {
      overflow: auto;
      margin-right: 10px;
      margin-left: 10px;

      &::-webkit-scrollbar {
        display: none;
      }

      .sessiontype-wrapper {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        max-width: 100%;
        margin: 0 auto;
        margin-top: 20px;
        justify-content: center;

        .sessiontype-item {
          font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
          position: relative;
          flex: 0 1 160px;
          width: 160px;
          height: 120px;
          max-width: 160px;
          max-height: 120px;
          border: 1px solid lightgrey;
          margin: 5px 0 10px 10px;
          border-radius: 10px;

          .text {
            position: absolute;
            top: 5px;
            left: 10px;
            width: 130px;
            text-align: left;
            font-size: 1rem;
            margin: 5px 5px 0 0;
            line-height: 1rem;
            font-weight: bold;
          }

          .count {
            display: block;
            position: absolute;
            bottom: 10px;
            right: 10px;
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  #wcpt-presentation-detail {
    .presentation-content {
      width: 100%;
      display: flex;
      flex-flow: row wrap;

      .left-side {
        width: 100%;
        height: 100%;
      }

      .stream-container {
        width: calc(100% - 40px);

        .stream {
          position: relative;
          width: 100%;
          padding-top: 56.25%;

          .info-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 3px dashed grey;

            .info {
              flex: 0 0 400px;
              font-size: 2rem;
              line-height: 2.2rem;
              color: grey;
              font-weight: bold;
            }
          }
        }
      }

      .right-side {
        margin-left: 10px;
        padding: 0 10px;
        border-left: 1px solid lightgrey;
        width: 100%;
      }

      .presentation-info {
        display: block;
        height: 60px;
        margin-left: 25px;
        font-size: 1.1rem;
        font-weight: bold;
        color: #b14545;
      }

      .persons {
        margin-left: 20px;
        width: 38%;
      }
    }

    .action-buttons {
      border-bottom: 1px solid rgb(231, 229, 229);
      display: flex;
      text-align: center;

      .action-btn {
        padding: 5px;
        font-size: 0.9rem;
        line-height: 0.9rem;
        color: black;
        border-radius: 50px;
        height: 40px;
        width: 150px;
        text-align: center;
        padding-top: 8px;
        margin: 20px 0px 20px 20px;
      }
    }

    .outline {
      margin-left: 25px;
    }
  }

  .wcpt-list {
    font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0;

    .wcpt-list-item {
      text-align: center;
      font-family: "Raleway", "Helvetica Neue", Helvetica, Arial, sans-serif;
      position: relative;
      flex: 0 0 calc(30% - 20px);
      width: auto;
      min-width: 340px;
      max-width: 33%;
      height: auto;
      display: block;
      background: #ffffff;
      border-radius: 20px;
      font-size: 1rem;
      margin: 20px 20px;
      color: #333333;
      -moz-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);
      border: none;
    }
  }

  .scroll-content {
    margin-top: 120px;
  }

  .presentation-list-item-modern {

    margin-left: -20px;

    .timeslot {
      display: flex;
      align-items: center;
      margin-bottom: -10px;

      .time-icon {
        font-size: 1.0rem;
        color: gray;
        margin-right: 2px
      }

      .time {
        padding-top: 1px;
        color: gray;
        font-size: 1rem;
        max-width: 80px;
        display: block;
        font-weight: bold;
      }
    }


    .content {
      font-size: 1rem;
      line-height: 1.1rem;

      .title {
        display: block;
        font-size: 1.0rem;
        padding-top: 6px
      }

      .speaker-line {
        display: block;
      }
    }
  }
}


#map-wrapper {
  display: block;
  margin: 5px auto 0 auto;
  width: 100%;
  text-align: center;

  .map-item-wrapper {
    display: inline-block;
    width: 180px;
    height: 180px;
    margin: 10px;
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
    transition: all ease-in 0.2s;
    -webkit-box-shadow: 1px 1px 2px 1px rgba(150, 150, 150, 0.4);
    -moz-box-shadow: 1px 1px 2px 1px rgba(150, 150, 150, 0.4);
    box-shadow: 1px 1px 2px 1px rgba(150, 150, 150, 0.4);

    &:hover, &:active, &:focus {
      -webkit-box-shadow: 1px 1px 2px 1px rgba(150, 150, 150, 0.6);
      -moz-box-shadow: 1px 1px 2px 1px rgba(150, 150, 150, 0.6);
      box-shadow: 1px 1px 2px 1px rgba(150, 150, 150, 0.6);
    }
  }

  .map-item {
    display: block;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 170px;
    cursor: pointer;
    position: relative;

    &:hover {
      .map-item-title {
        background-color: rgba($brand-color, 1);
      }
    }
  }

  .map-item-title {
    transition: background-color ease-in 0.2s;
    text-align: center;
    padding: 3px 6px;
    bottom: 0;
    width: 100%;
    position: absolute;
    color: #fff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgba($brand-color, 0.85);
  }
}

@media (max-width: 400px) {
  #map-wrapper {
    .map-item-wrapper {
      width: 140px;
      height: 140px;
    }

    .map-item {
      height: 130px;
    }
  }
}


.branded-background {
  background: url('assets/bg.png');
  background-size: 100% !important;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
}

.fixed-top {
  top: env(safe-area-inset-top);
}

.content-gap {
  height: calc(env(safe-area-inset-top) + 50px)
}

.docu-logo {
  background-image: url("../public/assets/documedias.svg");

  background-repeat: no-repeat;
  background-size: 100px 15px;
  height: 15px;
  margin-top: 2px;
}

.auditorium-title {
  background: $brand-color;
  color: $section-header-color;
}
