video {
  object-fit: cover;
}
.format-me{
  height: 45%;
}

.format-remote{
  height: 75%;
}

.videoroom-button-section{
  width: 100% !important;
  top: 100px;
  z-index: 6000;
}
  .stage{
    //background: linear-gradient(45deg, #bbb, #fff);
    background: url('assets/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    height: 100vh;
    overflow: hidden;
  }

  .hang-icon{
    transform: rotate(135deg); /* Equal to rotateZ(45deg) */
    margin-left: 10px;
  }
  .conference-title-container{
    width:100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    font-size: 1.3em !important;
    top: 30px;
  }

  .muted-icon{
    top: 0; 
    left: 0;
    width:100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute; 
    color: white;
    
  }
  .mute-button{
    width: 40%;
  }
  .mute-button:hover > .overlay{ //so I make an overlay when I hover to make the color darker
    width: 40%;
    background-color:#000;
    opacity:0.5;
  }
  .muted-icon-item{
    color: rgba(255, 255, 255, 0.75);;
    transform: scale(10);
    margin-top:35%;
  }

  .waiting-text{
    width:100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .spinner{
    justify-content: center;
    align-items: center;
    width:100%;
    transform: scale(2.5);
  }


  .no-camera{

    background-color: rgba(255,255,255,0.2);;
    color:white; 
    height: 300px !important; 
    border: 1px solid #ffffff;
    border-radius:15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    line-height: 2.2rem;

  }

  .background-no-camera{
    
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 5000;
  }

    .video-wrapper {
      
        width: 98%;
        max-height: 100%;
        height:100vh;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin: 20px auto;
        position:relative;
      


        .info-container {
          position: relative;
          width: 100%;
          padding-top: 56.25%;

          .info-wrapper {
            background-color: rgba(255,255,255,0.2);;
            position: absolute;
            top: 0;
            left: 0;
            width: 65%;
            height: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #fff;
            border-radius:15px;

            .info {
              flex: 0 0 400px;
              font-size: 2rem;
              line-height: 2.2rem;
              color: #fff;
              font-weight: bold;

              .spinner{
                display: block;
                text-align: center;
                margin-bottom: 15px;
                
                svg{
                  stroke:#fff;
                  height:60px;
                  width:60px;
                  
                }
              }
            }
          }
        }
        
        .video-item {
          position: relative;
                  
          video{
            border-radius: 15px;
            
            width: 100%;
            
          }
      
          .control {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            background: rgba(255,255,255,0.7);
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
            text-align: center;
            font-weight: bold;
            .mute-indicator {
              position: absolute;
              bottom: 3px;
              right: 6px;
              color: #000;
              width: 15px;
              height: 15px;
              display: none;
            }
            .username {
              padding-left: 10px;
              font-size: 0.8rem;
            }
          }
        }
        
        .video-item-me{

         height: 78%;
         margin-right: 5%;
          width:25%;
          z-index:0;
          position:absolute; top:0; bottom:0;right:0;
          border-radius: 15px;
          
        }
        .video-item-remote{

          width: 55% !important;
          margin-left: 5%;
          z-index:0;
          position:absolute; top:0; bottom:0;left:0;right:0;
          
        }
      }

      .end-call-button{
        background: rgb(231, 56, 56);
        color:#fff;
        position: absolute;
        bottom:10px;
        right:50%;
        border-radius: 50%;
        height:70px;
        width:70px;
        box-shadow: 0 0 5px #000;
        display: flex;
        justify-content: center;
        align-items: center;        
        

        i{
          transform: rotate(225deg);
          font-size: 1.8rem;
        
        }
      }

      .meeting-info{
        margin: 0 15px ;
        background: rgba(255,255,255,0.5);
        border-radius: 10px;
        padding: 5px 10px;
        .title{

        }
        .time{

        }
      }


@media (max-width:1024px){
  .muted-icon-item{
    color: rgba(255, 255, 255, 0.75);;
    transform: scale(7);
    margin-top:35%;
  }
}



//AKI AKI
.mobile-meeting-info{
  position: absolute;
  background: rgba(255, 255, 255, 0.34);
  left: 10px;
  top: 10px;
  padding: 5px;
  z-index: 100;
}

.mobile-title-container{
}


.small-spinner{
  display: inline-block;
  font-size: 35px;
  vertical-align: middle;
}
.mobile-nocamera{
  display: inline-block;
  vertical-align: middle;
}


.mobile-info-container{
  top:40%;
  position: absolute;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  width: 200px; 
  color: rgba(255, 255, 255, 0.75);;

}


.mobile-local-info-container{
  top:10% !important;
}
.mobile-container{
  height: 100%;

  background: url('assets/bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

  .big-video{

    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }
  .small-video{

    position: absolute;
    height: 100px;
    top: 10px;
    right: 10px;
    z-index: 20;
  }

  .mobile-buttons{
    position: absolute;
    width: 100%;
    bottom: 10px;
    text-align: center; 
    z-index: 30 !important;
  }

  .mobile-muted{
    top: 50px;
    right: 70px;
    position: absolute;
    z-index: 50 !important;
    color: rgba(255, 255, 255, 0.75);;
    transform: scale(5);
  }

@media (max-width:768px) and  (orientation:portrait) {






  //old mobile style
  .format-me{
    height: 45%;
  }

  .format-remote{
    height: 35%;
  }
  
  .videoroom-button-section{
    width: 100% !important;
    top: 100px;
    z-index: 6000 !important;
  }
  .stage{
    //background: linear-gradient(45deg, #bbb, #fff);
    background: url('assets/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    height: 100vh;
  }

  .conference-title-container{
    
    visibility: hidden;
  }
  .title-description{
    visibility: hidden;
  }

  .muted-icon{
    top: 0; 
    left: 0;
    width:100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute; 
    color: white;
    
  }
  .mute-button{
    width: 40%;
    margin-right: 10px;
  }
  .mute-button:hover > .overlay{ //so I make an overlay when I hover to make the color darker
    width: 80%;
    background-color:#000;
    opacity:0.5;
  }
  .muted-icon-item{
    color: rgba(255, 255, 255, 0.75);;
    transform: scale(5);
    margin-top:35%; //CAMBIAR
  }

  .waiting-text{
    width:100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .spinner{
    justify-content: center;
    align-items: center;
    width:100%;
    transform: scale(2.5);
  }

  .no-camera{

    background-color: rgba(255,255,255,0.2);;
    color:white; 
    height: 300px !important; 
    border: 1px solid #ffffff;
    border-radius:15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    line-height: 2.2rem;

  }

  .background-no-camera{
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 5000;
  }

    .video-wrapper {
      
        width: 98%;
        max-height: 100%;
        height:100vh;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin: 20px auto;
        position:relative;
      
        .info-container {
          position: relative;
          width: 100%;
          padding-top: 56.25%;

          .info-wrapper {
            background-color: rgba(255,255,255,0.2);;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 40%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #fff;

            .info {
              flex: 0 0 400px;
              font-size: 1.5rem;

              text-align: center;
              line-height: 2.2rem;
              color: #fff;
              font-weight: bold;

              .spinner{
                display: block;
                margin-bottom: 15px;
                
                svg{
                  stroke:#fff;
                  height:60px;
                  width:60px;
                  
                }
              }
            }
          }
        }

        .video-item {
          position: relative;
                  
          video{
            border-radius: 5px;
            width: 100%;
            box-shadow: 0 0 0px #000;
          }
      
          .control {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            background: rgba(255,255,255,0.7);
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
            text-align: center;
            font-weight: bold;
            .mute-indicator {
              position: absolute;
              bottom: 3px;
              right: 6px;
              color: #000;
              width: 15px;
              height: 15px;
              display: none;
            }
            .username {
              padding-left: 10px;
              font-size: 0.8rem;
            }
          }
        }
        .format-me{
          height: 45%;
        }
        .video-item-me{
        
          width:90%;
          margin-right: 5%;
          z-index:0;
          position:absolute; 
          top:41%; 
         
          border-radius: 15px;

          
        }
        .video-item-remote{
          width:90% !important;
          z-index:0;
          position:absolute; 
          left:0;
          right:0;
          
        }
      }

      .end-call-button{
        background: rgb(231, 56, 56);
        color:#fff;
        position: absolute;
        bottom:10px;
        left:42%;
        border-radius: 50%;
        height:70px;
        width:70px;
        box-shadow: 0 0 5px #000;
        display: flex;
        justify-content: center;
        align-items: center;        
        

        i{
          transform: rotate(225deg);
          font-size: 1.8rem;
        
        }
      }

      .meeting-info{
        background: rgba(255,255,255,0.5);
        border-radius: 10px;
        padding: 5px 10px;
        
        z-index: 5000 !important;
        width: 100%;
        margin: 0 ;
        margin: 0 0px ;
        .title{
          
        }
        .time{

        }
      }
}

@media (max-width:768px) and  (orientation:landscape) {
  






  .stage{
    //background: linear-gradient(45deg, #bbb, #fff);
    background: url('assets/bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 10px;
    height: 100vh;
  }

  .conference-title-container{
    width:40%;
    margin-left:60%;
    justify-content: right;
    align-items: right;
    text-align: right;
    position: absolute;
    top: 30px;
  }
  .title-description{
    visibility: hidden;
  }

  .muted-icon{
    top: 0; 
    left: 0;
    width:100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute; 
    color: white;
    
  }
  .mute-button{
    width: 40%;
  }
  .mute-button:hover > .overlay{ //so I make an overlay when I hover to make the color darker
    width: 40%;
    background-color:#000;
    opacity:0.5;
  }
  .muted-icon-item{
    color: rgba(255, 255, 255, 0.75);;
    transform: scale(5);
  }

  .waiting-text{
    width:100%;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .spinner{
    justify-content: center;
    align-items: center;
    width:100%;
    transform: scale(2.5);
  }

  .no-camera{

    background-color: rgba(255,255,255,0.2);;
    color:white; 
    height: 300px !important; 
    border: 1px solid #ffffff;
    border-radius:15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    line-height: 2.2rem;

  }

  .background-no-camera{
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 5000;
  }

    .video-wrapper {
      
        width: 98%;
        max-height: 100%;
        height:100vh;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        margin: 20px auto;
        position:relative;

        .info-container {
          background-color: rgba(255,255,255,0.2);;
          position: relative;
          width: 100%;
          padding-top: 56.25%;

          .info-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 48%;
            height: 63%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #fff;

            .info {
              flex: 0 0 250px;
              font-size: 1.2rem;
              text-align: center;
              line-height: 2.2rem;
              color: #fff;
              font-weight: bold;
          

              .spinner{
                display: block;
                margin-bottom: 15px;
                
                svg{
                  stroke:#fff;
                  height:60px;
                  width:60px;
                  
                }
              }
            }
          }
        }
      
        .video-item {
          position: relative;
                  
          video{
            border-radius: 15px;
            width: 100%;
            box-shadow: 0 0 0px #000;
          }
      
          .control {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            background: rgba(255,255,255,0.7);
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
            text-align: center;
            font-weight: bold;
            .mute-indicator {
              position: absolute;
              bottom: 3px;
              right: 6px;
              color: #000;
              width: 15px;
              height: 15px;
              display: none;
            }
            .username {
              padding-left: 10px;
              font-size: 0.8rem;
            }
          }
        }
      
        .video-item-me{
        
          width:48%;
          height: 0;
          z-index:0;
          position:absolute; 
          top:0; 
          bottom:0;
          right:0;
          border-radius: 15px;

          
        }
        .video-item-remote{
          width:48%;
          height:0;
          z-index:0;
          position:absolute; top:0; bottom:0;left:0;right:0;
          
        }
      }

      .end-call-button{
        background: rgb(231, 56, 56);
        color:#fff;
        position: absolute;
        bottom:10px;
        left:42%;
        border-radius: 50%;
        height:70px;
        width:70px;
        box-shadow: 0 0 5px #000;
        display: flex;
        justify-content: center;
        align-items: center;        
        

        i{
          transform: rotate(225deg);
          font-size: 1.8rem;
        
        }
      }

      .meeting-info{
        margin: 0 5px ;
        background: rgba(255,255,255,0.5);
        border-radius: 10px;
        padding: 5px 10px;
        .title{
          
        }
        .time{

        }
      }
}
