#content {
    position: relative;
    background-color: white;
    box-shadow: 0px -1px 10px rgba(0,0,0,0.4);
    padding-top: 200px;
  }

  .profile-img {
    background: url("assets/bg.png") no-repeat fixed;
    background-size: cover;
    padding-bottom:5px;
    position: absolute;
    top: -20px;
    width: 100%;
    z-index: 2;
    text-align: center;
    height: 130px;
  }
  .profile-text {
    position: absolute;
    top: 130px;
    width: 100%;
    z-index: 2;
    text-align: center;
  }
  #profile-name {
    color: #444;
    font-size: 26px;
  }
  #profile-description {
    font-size: 15px;
    color: #888;
  }
  #profile-description a {
    color: #888;
  }

  #profile-image {
    background: lightgrey;
    display: block;
    border-radius: 100px;
    border: 1px solid white;
    width: 128px;
    height: 128px;
    margin: 30px auto 0;
    box-shadow: 0px 0px 4px rgba(0,0,0,0.7);
  }

  .list-item-content {
    padding: 5px;
  }

  .list-item {
    color: #666666;
  }

  .profile-item{
      display: inline-block;
  }

  .content-info-item {
    display: inline-block;
    margin-top: 20px;
      margin-left: 20px;
      width: 32%;
    p {
      margin: 0;
      font-size: 1.0rem;
    }

    h2 {
      font-size: 0.9rem;
      color: #131313;
      text-transform: uppercase;
      letter-spacing: 0.04rem;
      border-top: solid 1px #131313;
      margin: 0 0 20px 0;
      opacity: 0.2;
    }

    ul, li {
      font-size: 0.9rem;
    }

    .desktop-profile-data{
        width: 800px !important;
        padding: 20px;
    }   
}

.edit-profile-button{
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    padding: 5px;
    border-radius: 25px;
    z-index: 11;
    position: absolute;
    right: 0px;
    top: 120px;
    padding: 8px;
    margin-left: 40px !important;
    margin-right: 40px !important;
    font-size: 36px;
  }
  
  .edit-profile-button:hover{
    color: white;
  }

  @media (max-width: 768px) {
    .content-info-item {
        width: 100%;
        margin-left: 0px;
    }


.edit-profile-button{
   
    top: 15px;
    padding:5px;
    margin-left: 40px !important;
    margin-right: 20px !important;
    right: 0px;
    font-size:32px;
    box-shadow: 0 6px 6px rgba(0,0,0,0.2);;
  }

  #profile-name {
    margin-top: 20px;
  }


  }
