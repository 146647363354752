.starrating-container{
    width: 100%;
    text-align: center;
}

.choice-container{
   
    position: absolute;
    width: 600px;
    height: 200px;
    z-index: 15;
    top: 200px;
    left: 50%;
    margin: -100px 0 0 -150px;
}
.choice-option{
    display: inline-block;
    margin-top: 10px;
}
.choice-text{
    margin-left: 10px;
}

.general-rating-container{
    width: 100%;
    text-align: center;
    margin-top: 40px;
    padding: 10px;
}

.starrating-stars{
    display: inline-block;
    margin-right: 30px;
    margin-left: 30px;
    font-size: 30px;
    cursor: pointer;
    color: #775c50;
}

.next-question-button{
    display: inline-block;
    margin-top: 50px;
    cursor: pointer;
}

.next-question-button-disabled {
    font-family: 'Raleway', "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-top: 50px;
    min-width: 280px;
    max-width: 280px;
    background: rgb(234, 233, 233);
    color: grey;
   
    align-items: center;
    justify-content: center;
    border: 1px solid grey;
    padding: 5px;
    border-radius: 25px;
    z-index: 11;
    
    
    padding: 8px;
    //margin-left: 40px !important;
    //margin-right: 40px !important;
    
    font-size: 18px;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
  }

.text-area { 
    height: 200px; 
    width: 550px;
}

@media (max-width: 768px) {

    .text-area { 
        height: 200px; 
        width: 300px !important;
    }

    .choice-container{
   
        position: absolute;
        width: 300px;
        height: 200px;
        z-index: 15;
        top: 50%;
        left: 50%;
        margin: -100px 0 0 -150px;
    }
    .starrating-stars{
        display: inline-block;
        margin-right: 15px;
        margin-left: 15px;
        font-size: 30px;
        cursor: pointer;
    }
}